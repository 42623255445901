@import url(https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/***************************************************************************

                HOME    PAGE

***************************************************************************/

body, html, #root, .app{
    height: 100%;
    font-family: gothmanBold;
    font-family: 'Baloo Tammudu 2', cursive;
}

body{
    margin: 0%;
    height: 100%;
    width: 100%;
    position: relative;
    color: white;
}

h1{
    margin: 0;
}

h2{
    margin: 0;
    font-size: calc(1.2vh + 1.2vw);
}

h3{
    margin: 0;
    font-size: calc(0.7vh + 0.7vw);
}

h4{
    margin: 0;
    font-size: calc(0.6vh + 0.6vw);
}

@media (max-width: 1500px){
    h2{
        font-size: calc(0.8vh + 0.8vw);
    }   
}

a{
    text-decoration: none;
    color: white;
}

.home-wrapper{
    position: relative;
    margin: 0;
    height: 100vh;
    width: 100%;
}

.boreas-subtitle{
    font-size: calc(2.15vh + 2.15vw);
    font-weight: 600;
    margin-top: 18vh;
    margin-bottom: 3vh;
    line-height: 28px;
}

#cookie-popup-wrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    height: 3vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.623);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    line-height: 10px;
    font-size: calc(0.5vh + 0.5vw);
    z-index: 9;
}

#cookie-text-wrapper{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}


#cookie-btn-wrapper{
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#cookie-btn-wrapper > button{
    height: 20px;
    width: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    border: solid 1px rgb(242, 135, 5);
}

.cookies-wrapper{
    position: relative;
    width: 100%;
    height: 100vh;
}

.cookies-text-wrapper{
    width: 80%;
    margin-left: 10%;
    color: black;
}

.loading-screen{
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero{
    height: 100vh;
    background-color: transparent;
}

#video-div{
    height: 87vh;
    width: 75%;
    margin-top: 10vh;
    margin-left: 12.5%;
    opacity: 1;
}

.video-transition-exit{
    opacity: 1;
}

.video-transition-exit-active{
    opacity: 0;
    transition: opacity 1s;
    transition-property: opacity;
}

.video-transition-exit-done{
    opacity: 0;
}

.hero-transition-enter{
    height: 0;
}

.hero-transition-enter-active{
    opacity: 1;
    height: 100vh;
    transition: opacity 1s, height 0s;
    transition-property: opacity, height;
    transition-delay: 1s;
}

.hero-transition-enter-done{
    opacity: 1;
    height: 100vh;
}

.loading-title{
    display: block;
    position: relative;
}

.loading-title::before{
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-animation-name: loading-anim;
            animation-name: loading-anim;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}

@-webkit-keyframes loading-anim{
    from{-webkit-transform: scaleX(0);transform: scaleX(0);}
    to{-webkit-transform: scaleX(1);transform: scaleX(1);}
}

@keyframes loading-anim{
    from{-webkit-transform: scaleX(0);transform: scaleX(0);}
    to{-webkit-transform: scaleX(1);transform: scaleX(1);}
}

.loading-transition-appear{
    opacity: 1;
}

.loading-transition-appear-active{
    opacity: 0;
    transition: opacity 500ms;
    transition-delay: 1500ms;
}

.loading-transition-appear-done{
    opacity: 0;
    display: none;
}

#warning-popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    width: 80%;
    margin-top: 20vh;
    margin-left: 10%;
    z-index: 20;
    background-color: rgba(80, 25, 25, 0.856);
}

#warning-section{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 90%;
    margin-top: 10vh;
    margin-left: 5%;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.856);
    border: solid 2px rgb(242, 135, 5);
    border-radius: 20px;
}

#back-to-homepage{
    font-size: calc(1.5vh + 1.5vw);
    border: solid 2px rgb(242, 135, 5);
    background-color: rgb(242, 135, 5);
    border-radius: 8px;
}

#warning-img{
    height: 130px;
    margin-bottom: 2vh;
    margin-top: 2vh;
}

.warning-text{
    font-size: calc(1vh + 1vw);
    margin: 0;
    padding: 0;
    color: white;
    text-align: center;
}

.header{
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    pointer-events: none;
}

#link-to-home{
    height: 190px;
    width: 190px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
}

#main-bg{
    z-index: -1;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(/static/media/Boreas1.a6bce053.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px -160px;
    -webkit-filter: brightness(120%);
            filter: brightness(120%);
}

#flyer-big{
    height: 90%;
}

@media (max-width: 1500px){
    #main-bg{
        background-position: 0 0;
    }   
}

#header-bg{
    position: relative;
    z-index: 1;
}

 .navbar-wrapper{
    position: absolute;
    z-index: 24;
    width: 65%;
    height: 100px;
    margin-left: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: rgb(242, 135, 5) 2px solid;
    box-sizing: border-box;
    background-color: rgb(13, 13, 13);
}

.menu-element{
    color: white;
    height: 100px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    pointer-events: all;
}

#dropdown-menu{
    height: 100px;
    width: 12vw;
    overflow: hidden;
    transition: 0.5s;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    pointer-events: all;
}

.dropdown-element{
    background-color: rgba(0, 0, 0, 0.589);
    font-size: calc(0.7vh + 0.7vw);
    text-align: center;
    justify-content: center;
    display: none;
}

#dropdown-midbar{
    display: none;
}

#dropdown-menu::-webkit-scrollbar{
    display: none;
}

#dropdown-menu:hover{
    overflow: auto;
    height: 302px;
}

#dropdown-menu:hover .dropdown-element, #dropdown-midbar{
    display: flex;
}

#boreas-arrow{
    height: 10px;
}

#logo-desktop{
    position: relative;
    height: 95%;
    margin-left: 10%;
}

#main-title{
    padding-top: 22vh;
    color: white;
    text-align: center;
    font-size: calc(3vh + 3vw);
}

.header-transition-enter{
    display: flex;
    opacity: 0;
    z-index: 8;
}

.header-transition-enter-active{
    opacity: 1;
    transition: 0.5s;
    display: flex;
    z-index: 8;
}

.header-transition-enter-done{
    opacity: 1;
    display: flex;
    z-index: 8;
}

.header-transition-exit{
    opacity: 1;
    display: flex;
}

.header-transition-exit-active{
    opacity: 0;
    display: flex;
    transition: 0.5s;
}

.header-transition-exit-done{
    opacity: 0;
    display: none;
}



.arrow-wrapper{
    position: absolute;
    left: 49%;
    bottom: 2vh;
}

.down-arrow{
    position: relative;
    height: 40px;
    width: 40px;
    -webkit-animation-name: arrow-anim;
            animation-name: arrow-anim;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes arrow-anim {
    0%{
        top: 0;
    }
    40%{
        top: 0;
    }
    50%{
        top: 1vh;
    }
    60%{
        top: 0;
    }
    100%{
        top: 0;
    }
}

@keyframes arrow-anim {
    0%{
        top: 0;
    }
    40%{
        top: 0;
    }
    50%{
        top: 1vh;
    }
    60%{
        top: 0;
    }
    100%{
        top: 0;
    }
}

#section2{
    width: 100%;
    background-color: transparent;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    line-height: 28px;
    margin-bottom: 10vh;
}

#main-event{
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-bottom: 5vh;
    background-color: rgba(0, 0, 0, 0.699);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    line-height: 28px;
}

@media (max-width: 1800px){
    
}

@media (max-width: 1500px){
    
}

#picture-frame{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 20vh;
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    cursor: pointer;
}

#picture-in-frame{
    width: 100%;
    border-radius: 20px;
}

#picture-frame-shadow{
    height: 22%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba(34, 34, 34, 0.568);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}

#click-to-grow{
    text-align: center;
    color: rgba(224, 224, 224, 0.795);
}

.flyer-transition-enter{
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    z-index: 7;
}

.flyer-transition-enter-active{
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    transition: all 0.5s;
    z-index: 7;
}

.flyer-transition-enter-done{
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    z-index: 7;
    background-color: rgba(0, 0, 0, 0.908);
}

.flyer-transition-exit{
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}

.flyer-transition-exit-active{
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.flyer-transition-exit-done{
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
}

#right-column{
    position: relative;
    height: 90vh;
    width: 25vw;
    margin-top: 5vh;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.column-block{
    height: 47.5%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px rgb(242, 135, 5);
}

#main-event-title{
    position: relative;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
    width: 90%;
    font-size: calc(1.3vh + 1.3vw);
}

#main-event-subtitle{
    position: relative;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
    width: 90%;
}

#main-event-subtitle::before{
    content: "";
    height: 1px;
    width: 100%;
    margin-left: 0%;
    background-color: rgb(242, 135, 5);
    position: absolute;
    bottom: -2vh;
}

.main-event-text, .event-informations{
    font-size: calc(0.9vh + 0.9vw);
    position: relative;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
    width: 90%;
    white-space: pre-wrap;
}

.main-event-text::before, .stopOver::before{
    content: "";
    height: 1px;
    width: 100%;
    margin-left: 0%;
    background-color: rgb(242, 135, 5);
    position: absolute;
    bottom: -1vh;
}

#date{
    font-size: calc(0.8vh + 0.8vw);
    position: relative;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
    width: 90%;
}

#booking-button{
    width: 40%;
    margin-left: 30%;
    margin-bottom: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: calc(0.8vh + 0.8vw);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 8px;
    background-color: black;
    color: white;
    font-size: calc(0.9vh + 0.9vw);
}

#reastaurant-menu-text::before{
    content: "";
    height: 1px;
    width: 70%;
    margin-left: 15%;
    background-color: rgb(242, 135, 5);
    position: absolute;
    bottom: -1vh;
}

.stopOver, .stopOverName{
    font-size: calc(0.8vh + 0.8vw);
    position: relative;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
    width: 90%;
    white-space: pre-wrap;
}

.stopOverName, .stopOver{
    margin-top: 1px;
    margin-bottom: 1px;
}

#footer{
    height: 8vh;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.footer-text{
    font-size: calc(0.8vh + 0.8vw);
    text-decoration: underline;
    margin: 0;
    padding: 0;
}

/***************************************************************************

                            A D M I N

***************************************************************************/

#auth-div-wrapper{
    margin: 0;
    width: 100%;
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#confirm-suppress{
    margin: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

/***************************************************************************

                BOOKING

***************************************************************************/


/********** Form *********************/
/********** Form *********************/
/********** Form *********************/

.booking-title{
    padding-top: 4vh;
    text-align: center;
}

#booking-steps-wrapper{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    margin-bottom: 6vh;
    width: 900px;
    height: 50px;
}

.booking-step{
    flex: 1 1;
    border-radius: 8px;
    border: solid 1px rgb(242, 135, 5);
    background-color: rgba(0, 0, 0, 0.63);
    color: white;
    font-size: 20px;
    text-transform: capitalize;
    cursor: pointer;
}

#booking-step1-wrapper, #ticket-select-div, #menu-choice, #option-div{
    background-color: rgba(0, 0, 0, 0.623);
    border: solid 1px rgb(242, 135, 5);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-bottom: 6vh;
}

#ticket-select-div, #menu-choice, #option-div{
    width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#ticket-select-div{
    align-items: flex-start;
}

.option{
    width: 60%;
    background-color: black;
    border: solid 1px rgb(242, 135, 5);
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 40px;
}

.option-title-and-checkbox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.option-title, .option-description{
    margin-top: 5px;
    margin-bottom: 5px;
}

#booking-step1-event{
    width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#booking-step1-event > img{
    width: 245px;
    height: 346px;
    border-radius: 8px;
    border: solid 1px rgb(242, 135, 5);
}

#booking-step1-event-text{
    width: 500px;
    margin-left: 50px;
    line-height: 32px;
}

#booking-step1-event-text-title{
    text-transform: uppercase;
    font-size: 20px;
    color: rgb(189, 188, 188);
    margin: 0;
}

#booking-step1-event-text-location{
    margin: 0;
    margin-top: 20px;
    text-transform: uppercase;
    color: rgb(189, 188, 188);
    font-size: 20px;
}

#booking-step1-event-text-title-name{
    font-size: 36px;
    font-weight: bold;
    margin: 0;
}

#booking-step1-event-text-date{
    font-size: 28px;
    text-transform: capitalize;
    margin: 0;
}

#booking-step1-event-text-stopover-name, #booking-step1-event-text-leaving{
    font-size: 22px;
    line-height: 38px;
    margin: 0;
    font-weight: bold;
}

#booking-step1-event-text-stopover-text, .booking-step1-event-text-boarding{
    font-size: 22px;
    line-height: 24px;
    margin: 0;
}

#booking-step1-who, #ticket-select-title, #menu-choice-title, #options-title{
    width: 100%;
    text-align: center;
    font-size: 36px;
    line-height: 46px;
    margin: 0;
    margin-top: 30px;
    text-transform: uppercase;
}

#who-is-in, #who-is-in-mod{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding: 10px;
    width: 400px;
    background-color: rgb(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    border: solid 1px rgb(242, 135, 5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

#who-is-in-mod{
    width: 550px;
    flex-wrap: wrap;
}

.who-is-in-nm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    height: 50px;
}

.who-is-in-m{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.who-is-in-icon{
    -webkit-filter: invert(100%);
            filter: invert(100%);
    height: 50px;
}

.who-is-in-icon-m{
    -webkit-filter: invert(100%);
            filter: invert(100%);
    height: 50px;
    margin-bottom: 5px;
}

.who-is-in-text{
    margin: 0;
    font-size: 18px;
    line-height: 20px;
    padding: 0;
}

.who-is-in-separation-bar{
    height: 60px;
    width: 2px;
    background-color: rgb(242, 135, 5);
}

.who-is-in-separation-bar-m{
    height: 100px;
    width: 2px;
    background-color: rgb(242, 135, 5);
}

.who-is-in-plus-minus{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    width: 140px;
}

.who-is-in-num{
    font-size: 24px;
    line-height: 28px;
    margin: 0;
}

.plus-minus-icon{
    height: 40px;
    -webkit-filter: invert(100%);
            filter: invert(100%);
}

#who-is-in-validation-div{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#who-is-in-validation-btn{
    border-radius: 20px;
    border: solid rgb(242, 135, 5) 1px;
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    letter-spacing: 4px;
}

#disabled-btn-div{
    width: 100px;
    height: 35px;
    border: solid 1px white;
    border-radius: 20px;
}

#disabled-btn{
    border: solid black 1px;
    border-radius: 50%;
    height: 33px;
    width: 35px;
    background-color: white;
}

#ticket-boreas, #ticket-vip{
    width: 250px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    margin-bottom: 50px;
    border-radius: 20px;
    border: solid 1px rgb(242, 135, 5);
    background-color: black;
}

#ticket-boreas-title{
    font-size: 26px;
    line-height: 50px;
    margin: 0;
    margin-top: 10px;
    text-align: center;
    -webkit-transform: scale();
            transform: scale();
}

.ticket-separation-bar{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
    height: 1px;
    background-color: rgb(242, 135, 5);
}

.ticket-tariff{
    text-align: center;
    font-size: 18px;
    margin: 0;
}

.ten-people-transition{
    -webkit-animation-name: ten-people;
            animation-name: ten-people;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes ten-people {
    0%{
        color: red;
    }
    25%{
        color: yellow;
    }
    50%{
        color: red;
    }
    75%{
        color: yellow;
    }
}

@keyframes ten-people {
    0%{
        color: red;
    }
    25%{
        color: yellow;
    }
    50%{
        color: red;
    }
    75%{
        color: yellow;
    }
}

.menu-count-wrapper{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 370px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
    border-radius: 20px;
    border: solid 1px rgb(242, 135, 5);
    background-color: black;
    padding: 10px;
    align-items: center;
}

.menu-description{
    font-size: 20px;
    text-decoration: underline;
    margin-right: 20px;
}

#booking-next-step-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 60px;
}

#booking-next-step{
    border-radius: 20px;
    border: solid 1px rgb(242, 135, 5);
    background-color: black;
    color: white;
    font-size: 28px;
    padding: 10px;
    text-transform: capitalize;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    margin: auto;
}

#step-2-wrapper, #step-4-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#step-3-wrapper{
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#coupon-div{
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    border: solid 1px rgb(242, 135, 5);
    background-color: rgba(0, 0, 0, 0.671);
    display: flex;
    flex-direction: column;
    align-items: center;
}

#coupon-title{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

#code-input-div{
    position: relative;
    width: 300px;
    height: 50px;
}

#coupon-input{
    position: absolute;
    height: 100%;
    width: 100%;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 8px;
    font-size: 16px;
    padding-left: 10px;
}

#coupon-submit{
    position: absolute;
    height: 38px;
    font-size: 16px;
    font-weight: bold;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    right: 5px;
    top: 8px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 2px;
    border: solid 1px rgb(242, 135, 5);
    background-color: rgb(0, 0, 0);
    border-radius: 20px;
    cursor: pointer;
}

#step-3-split{
    width: 540px;
    height: 2px;
    background-color: rgb(242, 135, 5);
    margin-top: 60px;
    margin-bottom: 60px;
}

#step-3-total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    border: solid 1px rgb(242, 135, 5);
    background-color: rgba(0, 0, 0, 0.671);
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 60px;
}

#step-4-wrapper{
    min-height: 90vh;
}

.form-and-stripe{
    width: 40%;
    margin-bottom: 5vh;
    position: relative;
    color: white;
    margin-right: 70px;
}

/* #stripe-wrapper{
    background-color: rgba(0, 0, 0, 0.699);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    padding: 10px;
    height: fit-content;
} */

#payment-element{
    background-color: rgba(0, 0, 0, 0.699);
    margin-bottom: 15px;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    padding: 10px;
}

#booking-gender-choice-wrapper{
    width: 100%;
}

#booking-gender-choice{
    width: 93%;
    height: 50px;
    margin-left: 2.25%;
    border-radius: 8px;
    border: solid 1px rgb(242, 135, 5);
    display: flex;
    flex-direction: row;
}

.booking-gender-button{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
}

.booking-gender-dot{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 2px rgb(242, 135, 5);
    margin-left: 10px;
    margin-right: 10px;
}

#stripe-hide{
    width: 100%;
    height: 100%;
    z-index: 6;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.918);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
}

.hide-text{
    font-size: calc(0.7vh + 0.7vw);
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.form{
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.7);
    padding-bottom: 3vh;
    padding-left: 2.5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 7;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
}

.label-and-input{
    width: 49%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 5vh;
}

.gender-input{
    color: rgb(61, 61, 61);
    font-weight: bolder;
    font-size: calc(0.6vh + 0.6vw);
    padding-top: 8px;
}

.form-input{
    width: 90%;
    font-size: calc(0.6vh + 0.6vw);
    height: 50px;
    margin: 0%;
    padding: 0%;
    padding-left: 3%;
    margin-left: 2.5%;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 8px;
    color: rgb(61, 61, 61);
    font-weight: bolder;
}

.simple-input{
    width: 87%;
}

.form-text{
    font-size: calc(0.6vh + 0.6vw);
    margin: 0%;
    padding: 0%;
    margin-left: 2.5%;
}



.fullDates{
    background-color: red;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  color: rgb(0, 0, 0);
}

#mybooking-wrapper{
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    border-bottom-left-radius: 20% 30%;
    border-bottom-right-radius: 20% 30%;
    padding-bottom: 40px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.733);
    margin-bottom: 5vh;
}

#mybooking-title-and-price{
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    font-size: 20px;
    font-weight: bold;
}

#mybookingdetails-price{
    width: 80px;
}

#mbd-detail-sec{
    padding-left: 10px;
    padding-right: 10px;
}

#mbd-top-title{
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    line-height: 20px;
}

#mbd-pic{
    position: relative;
    height: 200px;
    width: 100%;
    margin-top: 15px;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    cursor: pointer;
}

#mbd-event-title, #mbd-event-stopover, #mbd-ticket-type, .mbd-total-price{
    text-transform: uppercase;
    font-size: 24px;
    margin: 0;
    margin-top: 10px;
    line-height: 40px;
    font-weight: bold;
}

#mbd-event-date{
    font-size: 24px;
    margin: 0;
}

.mbd-split{
    height: 2px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: rgb(242, 135, 5);
}

.mbd-people-details{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#mybooking-info, #mybooking-display{
    width: 90%;
    margin-left: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#mybooking-display{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.mybooking-info-text{
    line-height: 20px;
    margin: 0;
}

#more-info-vip{
    color: rgb(242, 135, 5);
    text-decoration: underline;
    margin: 0;
    margin-left: 1%;
    font-size: 18px;
}

#vip-info-div{
    width: 95%;
    margin-left: 0;
    font-size: 18px;
    white-space: pre-wrap;
    line-height: 28px;
}

#payment-warning, #love-warning{
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    flex-direction: column;
}

#payment-warning > p, #love-warning > p, #payment-warning > button, #love-warning > button{
    font-size: 30px;
    color: rgb(242, 135, 5);
}

#payment-warning > p, #love-warning > p{
    margin-left: 150px;
    margin-right: 150px;
}

#payment-warning > button, #love-warning > button{
    color: white;
    border: solid 2px rgb(242, 135, 5);
    border-radius: 8px;
    background-color: #000;
    padding: 5px;
    cursor: pointer;
}


/****************************************************
**********************STRIPE*************************
****************************************************/

/* form {
    width: 30vw;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }*/
  .pop-up-wrapper{
    min-height: 100vh;
    width: 100%;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }

  .pop-up{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.849);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    padding: 80px;
  }

  .pop-up-text{
      font-size: calc(1vh + 1vw);
      color: white;
      margin: 0;
      font-weight: bold;
      line-height: 35px;
  }

  .pop-up-button{
    background: #000000;
    color: #ffffff;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 250px;
    height: 60px;
    padding: 8px;
    font-size: calc(0.6vh + 0.6vw);
    text-transform: uppercase;
  }

  #succeeded-payment-booking-number{
    margin-top: 40px;
    margin-bottom: 30px;
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  #succeeded-payment-booking-number > div {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .succeeded-payment-text{
    line-height: 35px;
    margin: 0;
    font-size: 16px;
  }

  .booking-code-text{
      font-size: calc(1vh + 1vw);
  }

  #succeeded-payment-links{
    margin-top: 40px;
    height: 50px;
    width: 550px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  .stripe{
    position: relative;
    width: 100%;
    height: 80px;
    padding-left: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: transparent;
    border: none;
    border-radius: 20px;
  }

  .price{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 30%;
      justify-content: center;
      align-items: center;
  }

  .guest-and-price{
      height: 12vh;
  }
  
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  
  .hidden {
    display: none;
  }
  
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  
  #card-element {
    border-radius: 4px 4px 0 0;
    border: 1px solid rgba(50, 50, 93, 0.1);
    width: 92.5%;
    height: 50%;
    background: white;
    box-sizing: border-box;
    margin-left: 2.5%;
  }
  
  #payment-request-button {
    margin-bottom: 32px;
  }

  #payment-form{
      height: 90%;
      width: 100%;
      margin: 0;
      padding: 0;
  }
  
  #submit {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 92.5%;
    margin-left: 2.5%;
    height: 50%;
  }
  
  #submit:hover {
    -webkit-filter: contrast(115%);
            filter: contrast(115%);
  }
  
  #submit:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media (max-height: 1000px){
    @media (max-width: 500px){
        
    }
}

@media (max-aspect-ratio: 1/1), (max-width: 600px){
    .form-and-stripe{
        width: 90%;
        margin-left: 3.75%;
    }
    .booking-title-text{
        font-size: calc(1.3vh + 1.3vw);
    }

    #stripe-hide{
        align-items: initial;
    }

    #stripe-hide > p{
        margin-left: 8vw;
        margin-right: 8vw;
    }

    .form-input{
        font-size: calc(1vh + 1vw);
    }
}

/***************************************************************************

                        C O N T A C T

***************************************************************************/

.contact-send-btn{
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    transition: all 0.1s;
}

.send-form{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.1s;
}

/***************************************************************************

                        E V E N T S

***************************************************************************/

#no-event-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.527);
}

#no-event-text{
    font-size: calc(2vh + 2vw);
}

#events-main-wrapper{
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

#events-wrapper{
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 25vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#events-list{
    width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#displayed-event{
    width: 33%;
    min-height: 80vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

#events-wrapper-mobile{
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 15vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#events-list-mobile{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#displayed-event-mobile{
    width: 90%;
    min-height: 80vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 5%;
}

/* .noevent-transition-appear{
    display: none;
}

.noevent-transition-appear-active{
    display: none;
}

.noevent-transition-appear-done{
    display: none;
} */


/***************************************************************************

                    SEARCH FOR BOOKINGS

***************************************************************************/

.search-div{
    display: flex;
    border: rgba(242, 135, 5, 0.479) 2px solid;
    padding: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 0.5vh;
    margin-left: 1vw;
}

/**************************************************************************






                    THE BOAT









***************************************************************************/

#the-boat-main-wrapper{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    width: 100%;
}

#the-boat-upper-wrapper{
    width: 85%;
    margin-left: 7.5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

#the-boat-upper-links{
    height: 50px;
    width: 300px;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.623);
    border: solid 2px rgb(242, 135, 5);
    border-radius: 20px;
}

.the-boat-upper-links-text-wrapper{
    height: 100%;
    width: 50%;
}

.the-boat-upper-links-text{
    text-align: center;
    font-weight: bold;
    margin: 0;
    margin-top: 10px;
    font-size: 18px;
}

#the-boat-main-title{
    font-size: calc(1.4vh + 1.4vw);
    font-weight: bold;
    margin-bottom: 2vh;
}

#the-boat-main-pic-main-text{
    height: 700px;
    width: 1400px;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.692);
    border: solid 2px rgb(242, 135, 5);
    border-radius: 20px;
}

#the-boat-main-pic{
    height: 90%;
    margin-top: 2.5%;
    margin-left: 2.5%;
    width: 25%;
    position: relative;
}

#the-boat-main-pic > img{
    height: 100%;
    border-radius: 20px;
}

#the-boat-main-text{
    height: 100%;
    width: 64%;
    padding: 20px;
}

.the-boat-story-text{
    font-size: 20px;
    line-height: 28px;
}

#the-boat-pictures-title{
    margin-top: 5vh;
    font-size: calc(1.4vh + 1.4vw);
    font-weight: bold;
    text-align: center;
}

#the-boat-displayed-pic-main-wrapper{
    width: 100%;
    position: relative;
}

#the-boat-displayed-pic-wrapper{
    height: 600px;
    width: 60%;
    margin-left: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    background-color: rgba(0, 0, 0, 0.623);
    border: solid 2px rgb(242, 135, 5);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

#the-boat-displayed-pic{
    height: 100%;
    border-radius: 20px;
}

#the-boat-pic-selector-wrapper{
    width: 60%;
    margin-left: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#the-boat-pic-selector{
    width: 96%;
    height: 110px;
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.616);
    border: solid 2px rgb(242, 135, 5);
    border-radius: 8px;
    overflow-x: scroll;
}

.pic-wrapper-in-pic-selector{
    height: 95%;
    width: 150px;
    overflow: hidden;
    margin-right: 5px;
    margin-left: 5px;
    background-color: rgba(0, 0, 0, 0.705);
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.pic-in-pic-selector{
    width: 150px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

#the-boat-mobile-pic-main-wrapper{
    height: 80vh;
    width: 100%;
}

#the-boat-mobile-pic-wrapper{
    width: 95%;
}

#mobile-pic-displayer-wrapper{
    height: 100vh;
    width: 100%;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

#mobile-displayed-pic-wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1600px){
    #the-boat-main-pic-main-text{
        height: 600px;
        width: 1200px;
    }

    .the-boat-story-text{
        font-size: 18px;
        line-height: 21px;
    }
}

@media (max-width: 1367px){
    #the-boat-main-pic-main-text{
        height: 500px;
        width: 1000px;
    }

    #the-boat-main-text{
        padding: 15px;
    }

    .the-boat-story-text{
        font-size: 15px;
        line-height: 18px;
    }
}

@media (max-width: 1100px){
    #the-boat-main-pic-main-text{
        height: 400px;
        width: 800px;
    }

    #the-boat-main-text{
        padding: 10px;
    }

    .the-boat-story-text{
        font-size: 13px;
        line-height: 15px;
    }
}

@media (max-aspect-ratio: 1/1), (max-width: 600px){
    #the-boat-upper-wrapper{
        width: 90%;
        margin-left: 5%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-top: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #the-boat-main-title{
        color: rgb(242, 135, 5)
    }

    #the-boat-main-pic-main-text{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: visible;
        border: none;
        background-color: transparent;
    }

    #the-boat-main-pic{
        width: 100%;
        margin: 0;
        border: solid 2px rgb(242, 135, 5);
        border-radius: 20px;
        margin-bottom: 3vh;
    }

    #the-boat-main-pic > img{
        width: 100%;
        border-radius: 20px;
    }

    #the-boat-main-text{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
        padding: 0%;
        border: solid 2px rgb(242, 135, 5);
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.692);
        margin-bottom: 3vh;

    }

    .the-boat-story-text{
        padding-right: 10px;
        padding-left: 10px;
        font-size: calc(1vh + 1vw);
    }
}

/**************************************************************************






                    MY    BOOKING









*************************************************************************/

#main-booking-wrapper{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.623);
}

#enter-info-mybooking{
    margin-top: 200px;
    margin-bottom: 22vh;
    padding: 20px;
    width: 330px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.856);
    border: solid 2px rgb(242, 135, 5);
    border-radius: 20px;
}

.mybooking-title{
    font-size: 26px;
}

#bad-search-popup{
    color: red;
    background-color: rgb(255, 175, 175);
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px red;
    border-radius: 8px;
    line-height: 15px;
}

.mybooking-label{
    text-transform: uppercase;
    font-size: 16px;
    margin: 0;
}

#my-booking-second-label{
    margin-top: 30px;
}

.mybooking-input{
    font-size: 16px;
    padding-left: 10px;
    height: 40px;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 8px;
    color: rgb(61, 61, 61);
}

#where-to-find-booking-number{
    font-size: 16px;
    line-height: 20px;

}

#mybooking-forgotten-link{
    font-size: 16px;
    text-decoration: underline;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
}

#mybooking-validate-check{
    color: white;
    background-color: black;
    margin-top: 30px;
    cursor: pointer;
}

#mybooking-details-wrapper{
    margin-top: 200px;
    margin-bottom: 33vh;
    width: 100%;
}

#mybooking-details-and-options{
    width: 60%;
    margin-left: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#mybooking-options{
    width: 350px;
    height: 250px;
    margin-top: 40px;
    border: solid 2px rgb(242, 135, 5);
    border-radius: 20px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.mybooking-options-text{
    font-size: 20px;
    margin: 0;
    padding: 0;
}


/****************************************************************************
*****************************************************************************
*****************************************************************************
*****************************************************************************
*****************************************************************************
*****************************************************************************
****************                                                *************
****************                                                *************
****************                                                *************
****************            M O B I L E      C S S              *************
****************                                                *************
****************                                                *************
****************                                                *************
*****************************************************************************
*****************************************************************************
*****************************************************************************
****************************************************************************/

@media (max-aspect-ratio: 1/1), (max-width: 600px){
    #main-bg{
        background-image: url(/static/media/mobilebg1.0938e2a8.jpg);
        background-position: 50% 50%;    
    }

    h3{
        font-size: calc(1.5vh + 1.5vw);
    }

    h4{
        font-size: calc(1.4vh + 1.4vw);
    }

    #warning-popup{
        margin-top: 10vh;
    }

    #warning-img{
        height: 50px;
        margin-bottom: 2vh;
        margin-top: 0vh;
    }

    .warning-text{
        font-size: calc(1.3vh + 1.3vw);
        line-height: 22px;
    }

    .header-mobile{
        position: fixed;
        z-index: 8;
        top: 0;
        left: 0;
        width: 100%;
        color: white;
        pointer-events: none;
    }

    #header-bg-mobile{
        position: relative;
        z-index: 1;
        height: 70px;
    }

    #link-to-home-mobile{
        position: absolute;
        width: 58px;
        height: 58px;
        left: 7px;
        top: 7px;
        border-radius: 50%;
        z-index: 2;
        pointer-events: all;
    }

    .boreas-subtitle{
        font-size: calc(2.15vh + 2.15vw);
        font-weight: 600;
        margin-top: 8vh;
        margin-bottom: 1.5vh;
        color: black;
    }

    #hamburger{
        position: fixed;
        z-index: 10;
        top: 6px;
        right: 10px;
        color: white;
        height: 20px;
        pointer-events: all;
    }

    #mobile-menu{
        width: 100%;
        background-color: black;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
    }

    .mobile-menu-transition-enter{
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
    }
    
    .mobile-menu-transition-enter-active{
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
        -webkit-transform-origin: top;
                transform-origin: top;
    }
    
    .mobile-menu-transition-enter-done{
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }
    
    .mobile-menu-transition-exit{
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }
    
    .mobile-menu-transition-exit-active{
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s;
        -webkit-transform-origin: top;
                transform-origin: top;
    }
    
    .mobile-menu-transition-exit-done{
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
    }

    .navbar-wrapper-mobile{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 8vh;
    }

    .menu-section-mobile{
        height: 7vh;
        width: 100%;
        padding-left: 5%;
        border-bottom: rgba(242, 135, 5, 0.479) 1px solid;
        border-top: rgba(242, 135, 5, 0.479) 1px solid;
        display: flex;
        align-items: center;
        margin-top: 1%;
        font-size: calc(1vh + 1vw);
        pointer-events: all;
    }

    .hero-mobile{
        height: 100vh;
        background-color: transparent;
    }

    #main-title{
        padding-top: 17vh;
        color: white;
        text-align: center;
        font-size: calc(6vh + 6vw);
    }

    #video-div{
        height: 28vh;
        width: 100%;
        margin-left: 0;
        margin-top: 0;
    }

    .video-transition-exit{
        opacity: 1;
    }
    
    .video-transition-exit-active{
        opacity: 0;
        transition: opacity 1s;
        transition-property: opacity;
    }
    
    .video-transition-exit-done{
        opacity: 0;
    }

    #cookie-popup-wrapper{
        height: 4vh;
    }

    .arrow-wrapper{
        position: absolute;
        bottom: 13vh;
    }

    #section2{
        min-height: 100vh;
        width: 90%;
        margin-left: 5%;
        line-height: 22px;
        flex-direction: column;
    }

    #main-event{
        line-height: 22px;
    }

    #main-event-title{
        margin-top: 5%;
        font-size: calc(1.8vh + 1.8vw);
    }

    #main-event-subtitle{
        font-size: calc(1.3vh + 1.3vw);
    }

    #main-event-subtitle::before{
        bottom: -1vh;
    }

    .main-event-text{
        margin-top: 1%;
    }

    .main-event-text, .stopOver, #stopover-link, #date, #booking-button, .event-informations{
        margin-top: 5%;
        font-size: calc(1.3vh + 1.3vw);
        line-height: 20px;
    }

    .stopOverName{
        font-size: calc(1.3vh + 1.3vw);
        line-height: 20px;
    }

    .stopOver{
        margin-top: 0px;
    }

    #main-event{
        width: 100%;
        margin-top: 7.5vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    #no-event-text{
        padding-left: 5%;
    }

    #right-column{
        width: 100%;
    }

    #flyer-big{
        height: auto;
        width: 90%;
    }

    .label-and-input{
        margin-top: 1vh;
    }

    .form-text{
        font-size: calc(0.9vh + 0.9vw);
    }

    .form-input{
        height: 40px;
    }

    .gender-input{
        font-size: 14px;
    }

    .form-and-stripe{
        min-height: 360px;
        margin-right: 0;
        margin-left: 1%;
    }

    .form{
        width: 95%;
    }

    .stripe{
        width: 95%;
    }

    #step-2-wrapper, #step-4-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: unset;
        align-items: center;
    }

    #step-4-wrapper{
        min-height: 85vh;
    }

    #booking-steps-wrapper{
        width: 300px;
        height: 40px;
    }

    #booking-step1-event{
        flex-direction: column;
        width: 290px;
        align-items: center;
    }

    #booking-step1-event > img{
        margin-bottom: 15px;
        width: 245px;
        height: 346px;
    }

    #booking-step1-event-text{
        margin: 0;
        width: 290px;
    }

    #booking-step1-event-text-title{
        font-size: 18px;
    }

    #booking-step1-event-text-title-name{
        font-size: 20px;
        line-height: 20px;
    }

    

    #booking-step1-event-text-date{
        font-size: 20px;
        line-height: 24px;

    }

    #booking-step1-event-text-location{
        font-size: 18px;
        margin-top: 5px;
    }

    #booking-step1-event-text-stopover-name, #booking-step1-event-text-leaving{
        font-size: 22px;
        line-height: 20px;
    }

    #booking-step1-event-text-stopover-text, .booking-step1-event-text-boarding{
        font-size: 16px;
    }

    #who-is-in{
        width: 250px;
        padding: 5px;
        margin: 0;
    }

    .who-is-in-icon{
        height: 30px;
    }

    .who-is-in-nm{
        height: 40px;
    }

    .who-is-in-text{
        font-size: 14px;
    }

    #who-is-in-mod{
        width: 270px;
    }

    .who-is-in-plus-minus{
        width: 130px;
    }

    #booking-step1-who{
        font-size: 22px;
        margin-top: 15px;
    }

    #ticket-select-title{
        margin-top: 5px;
        font-size: 22px;
    }

    #ticket-select-div, #menu-choice{
        align-items: center;
        width: 290px;
    }

    .menu-count-wrapper, .option{
        width: 90%;
    }

    .menu-count-wrapper{
        flex-direction: column;
        min-width: 90%;
    }

    #ticket-boreas, #ticket-vip, #option-div{
        width: 290px;
    }

    #booking-next-step{
        font-size: 22px;
    }

    .booking-title-text{
        font-size: calc(1.1vh + 1.1vw);
        color: rgb(242, 135, 5);
    }

    #step-3-total{
        width: 290px;
    }

    #step-3-split{
        width: 330px;
    }

    #coupon-div{
        width: 290px;
        padding: 20px;
    }
    
    #coupon-title{
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
    
    #code-input-div{
        width: 250px;
        height: 40px;
    }
    
    #coupon-input{
        position: absolute;
        height: 100%;
        width: 100%;
        border: solid 1px rgb(242, 135, 5);
        border-radius: 8px;
        font-size: 14px;
    }
    
    #coupon-submit{
        position: absolute;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        color: white;
        right: 4px;
        top: 8px;
        margin: 0;
        padding-left: 5px;
        padding-right: 5px;
        letter-spacing: 2px;
        border: solid 1px rgb(242, 135, 5);
        background-color: rgb(0, 0, 0);
        border-radius: 20px;
        cursor: pointer;
    }

    .mbd-split{
        margin-bottom: 10px;
        margin-top: 10px;
    }

    #mbd-pic{
        margin-top: 5px;
    }

    #mbd-top-title{
        font-size: 18px;
        line-height: 10px;
    }

    #mbd-event-date{
        font-size: 16px;
    }

    #mbd-event-title, #mbd-event-stopover, #mbd-ticket-type, .mbd-total-price{
        text-transform: uppercase;
        font-size: 18px;
        margin: 0;
        margin-top: 8px;
        line-height: 30px;
        font-weight: bold;
    }

    .mybooking-info-text{
        font-size: 14px;
    }

    #more-info-vip{
        font-size: 12px;
        padding-top: 5px;
    }
    
    #vip-info-div{
        width: 95%;
        margin-left: 0;
        font-size: 12px;
        line-height: 22px;
    }

    #payment-warning > p, #love-warning > p{
        font-size: 22px;
        margin-left: 50px;
        margin-right: 50px;
    }

    #payment-warning > button, #love-warning > button{
        font-size: 22px;
        margin-top: 20px;
    }

    #stripe{
        height: 10vh;
    }

    .guest-and-price{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        justify-content: center;
    }

    .pop-up-wrapper{
        align-items: flex-start;
    }

    #succeeded-payment{
        margin-top: 10vh;
        width: 330px;
        padding: 20px;
    }

    .hide-text{
        font-size: 12px;
    }

    .pop-up-text{
        font-size: calc(1.6vh + 1.6vw);
    }

    .succeeded-payment-text{
        font-size: calc(1.2vh + 1.2vw);
    }

    #succeeded-payment-booking-number{
        width: 290px;
    }

    .booking-code-text{
        font-size: 16px;
    }

    #succeeded-payment-links{
        width: 290px;
        margin-bottom: 30px;
    }

    .pop-up-button{
        width: 130px;
        font-size: 10px;
        height: 65px;
    }

    #enter-info-mybooking{
        width: 300px;
    }

    #mybooking-wrapper{
        width: 290px;
    }

    #mybooking-details-wrapper{
        margin-top: 100px;
        margin-bottom: 8vh;
    }

    #mybooking-details-and-options{
        width: 95%;
        margin-left: 2.5%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #mybooking-title-and-price{
        width: 95%;
        margin-left: 2.5%;
    }
    
    #mybookingdetails-price{
        width: 100px;
    }

    .mybooking-title{
        font-size: 22px;
    }

    #the-boat-main-pic{
        border: none;
    }

    #the-boat-main-pic > img{
        border: rgb(242, 135, 5) solid 2px;
    }
}

/********************************************************************



            Tablet





***********************************************************************/

@media (min-width: 600px) and (max-width: 1400px){
    #header-bg-mobile{
        height: 120px;
    }
}
#contact-main-wrapper{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-bg{
    z-index: -1;
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(/static/media/Boreas1.a6bce053.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px -160px;
    -webkit-filter: brightness(120%);
            filter: brightness(120%);
}

@media (max-width: 1600px){
    #contact-bg{
        background-position: 0 0;
    }   
}

.contact-form-wrapper{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 2vh;
    padding-bottom: 5vh;
    padding-left: 1%;
    margin-bottom: 8vh;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
}

#form-first-line1, #form-first-line2{
    width: 100%;
    text-transform: none;
    font-weight: bold;
    font-size: calc(0.8vh + 0.8vw);
    margin-top: 2vh;
}

#message-area, .checkbox-div{
    width: 100%;
    margin-left: 2.5%;
}

#message{
    height: 15vh;
    width: 95%;
    margin-left: 0;
}

.form-text{
    text-transform: uppercase;
}

.form-span{
    color: red;
}

#date-field, #message{
    padding-left: 0;
}

#contact-send{
    width: 30%;
    margin-bottom: 2vh;
    margin-top: 5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: calc(0.8vh + 0.8vw);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: calc(0.9vh + 0.9vw);
}

.checkbox-div{
    margin-top: 2vh;
    width: 100%;
    margin-left: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-checkbox{
    padding: 0;
    margin-left: 2%;
    height: 30px;
    width: 30px;
}

.checkbox-text{
    padding: 0;
    margin: 0;
    margin-left: 3%;
    margin-top: 5px;
    font-size: calc(0.8vh + 0.8vw);
}

#form-sent-popup{
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.801);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#sent-popup{
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.753);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 20px;
}

#first-checkbox{
    margin-top: 4vh;
}

#back-button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 2vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: calc(0.8vh + 0.8vw);
    border: solid 1px rgb(242, 135, 5);
    border-radius: 8px;
    background-color: black;
    color: white;
    font-size: calc(0.9vh + 0.9vw);
}

#date-field{
    padding-left: 3%;
}

@media (max-aspect-ratio: 1/1), (max-width: 600px){
    .contact-form-wrapper{
        width: 90%;
    }

    .form-checkbox{
        height: 18px;
        width: 18px;
    }

    .checkbox-div{
        margin-top: 2vh;
    }
    
    #contact-bg{
        background-image: url(/static/media/mobilebg1.0938e2a8.jpg);
        background-position: 50% 50%;    
    }

    #message{
        height: 10vh;
    }

    .checkbox-text{
        font-size: calc(0.9vh + 0.9vw);
    }
}
